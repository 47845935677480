<template>
  <span class="percentage-value" :class="{ large: isLarge, xlarge: isXLarge }" v-if="Number.isFinite(value)">
    <span class="value">{{ formattedValue }} </span>
    <sup class="p-ml-1">%</sup>
  </span>
</template>

<script>
export default {
  name: "PercentageValue",
  props: {
    value: Number,
    size: {
      type: String,
      default: "medium",
    },
    decimal: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      isLarge: this.size === "large",
      isXLarge: this.size === "xlarge",
    };
  },
  computed: {
    formattedValue() {
      return parseFloat(this.value.toFixed(this.decimal));
    },
  },
};
</script>
<style scoped lang="scss">
.percentage-value {
  display: flex;
  line-height: 1;
  align-items: end;

  &.large {
    font-size: 1.36em;

    span.value {
      font-weight: 600;
    }
  }

  &.xlarge {
    font-size: 1.6em;
  }

  .value {
    align-self: flex-start;
    margin-top: 0.1em;
    margin-right: 0.2em;
    font-size: 1em;
    font-weight: 600;
  }
}
</style>
